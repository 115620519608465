import React, { useState, useMemo, useRef } from 'react'
// import TinderCard from '../react-tinder-card/index'
import TinderCard from 'react-tinder-card'
import logo from '../assets/we_logo.png'
import gif from '../assets/confetti.gif'

const db = [
  {
    name: 'Richard Hendricks',
    url: './img/card1.png'
  },
  {
    name: 'Erlich Bachman',
    url: './img/card2.png'
  },
  {
    name: 'Monica Hall',
    url: './img/card3.png'
  },
  {
    name: 'Jared Dunn',
    url: './img/card4.png'
  },
  {
    name: 'Dinesh Chugtai',
    url: './img/card5.png'
  }
]

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array
}

function Advanced () {
  const [shuffle_id, setId] = useState(123)
  const [lastDirection, setLastDirection] = useState()
  const [cardsArr, setCardsArr] = useState(shuffleArray([...Array(24).keys()]))
  const [currentIndex, setCurrentIndex] = useState(cardsArr.length - 1)
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex)

  const childRefs = useMemo(
    () =>
      Array(cardsArr.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  )

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val)
    currentIndexRef.current = val
  }

  const canGoBack = currentIndex < cardsArr.length - 1

  const canSwipe = currentIndex >= 0

  const refreshPage = ()=>{
    window.location.reload();
  }

  // set last direction and decrease current index
  const swiped = (direction, index) => {
    setLastDirection(direction)
    updateCurrentIndex(index - 1)
  }

  const outOfFrame = (name, idx) => {
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  }

  const swipe = async (dir, index) => {
    if (canSwipe && currentIndex < cardsArr.length) {
      // if you click through the next button too quickly we get an error, 
      // this just catches it to prevent the error from crashing the app.
      try {
        await childRefs[currentIndex].current.swipe(dir) // Swipe the card!
      } catch (e) {
        console.log('Error')
      }
    }
  }

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) return
    const newIndex = currentIndex + 1
    updateCurrentIndex(newIndex)
    await childRefs[newIndex].current.restoreCard()
  }

  const reshuffle = () => {
    setId(Math.random());
    updateCurrentIndex(cardsArr.length - 1);
    setCardsArr(shuffleArray([...Array(24).keys()]))
  }

  return (
    <div>
      <link
        href='https://fonts.googleapis.com/css?family=Damion&display=swap'
        rel='stylesheet'
      />
      <link
        href='https://fonts.googleapis.com/css?family=Alatsi&display=swap'
        rel='stylesheet'
      />
      <img src={logo} alt="We Connect Cards, Creating Conversation." className="logo"/>
      <div key={shuffle_id} className='cardContainer'>
        {cardsArr.map((val, index) => (
          <TinderCard
            ref={childRefs[index]}
            className='swipe'
            key={index}
            onSwipe={(dir) => swiped(dir, index)}
            onCardLeftScreen={() => outOfFrame(val, index)}
          >
            <div
              style={{ backgroundImage: 'url(./img/card' + (val+1) + '.png)' }}
              className='card'
            >
            </div>
          </TinderCard>
        ))}
        {currentIndex === -1 &&
          <div>
            <h2 className="finished-text">
              Thanks for asking! Continue making connection easy by checking out the links below.
            </h2>
            <img src={gif} alt="Celebration GIF." className="gif"/>
          </div>
        }
      </div>
      <div className='buttons'>
        <button style={{ backgroundColor: !canGoBack ? '#c3c4d3' : '#58585B' }} onClick={() => goBack()}>Back</button>
        { currentIndex > -1 && <button style={{ backgroundColor: !canSwipe && '#c3c4d3' }} onClick={() => swipe('right')}>Next</button>}
        { currentIndex === -1 && <button style={{ backgroundColor: '#903F97' }} onClick={() => reshuffle()}>Reshuffle</button>}
      </div>
      <div className="cta-container">
        <a href="https://weand.me/ideas/">Get Free Tools</a>
        <a href="https://www.youtube.com/weandme?sub_confirmation=1">Video Tutorials</a>
        <a href="https://weand.me/store/">Buy the Full Deck</a>
        <a href="https://weand.me/instructions">Instructions</a>
      </div>
    </div>
  )
}

export default Advanced
